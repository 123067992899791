import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import * as styles from '../../assets/css/code.module.css'
import Layout from '../../components/Layout'
import SmartLink from '../../components/SmartLink'

const essayQuery = graphql`
  query {
    content(uid: { eq: "c9" }) {
      headline
      subline
      byline
      poster {
        childImageSharp {
          full: gatsbyImageData(layout: FULL_WIDTH)
          thumb: gatsbyImageData(height: 150, placeholder: BLURRED)
        }
      }
      urban {
        childImageSharp {
          full: gatsbyImageData(layout: FULL_WIDTH)
          thumb: gatsbyImageData(height: 150, placeholder: BLURRED)
        }
      }
      items {
        content
        images {
          childImageSharp {
            full: gatsbyImageData(layout: FULL_WIDTH)
            thumb: gatsbyImageData(height: 150, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

const TheCode = () => {
  const { content } = useStaticQuery(essayQuery)
  const type1Imgs = []
  content['items'][0]['images'].forEach((element) => {
    type1Imgs.push(element.childImageSharp)
  })
  const type2Imgs = []
  content['items'][1]['images'].forEach((element) => {
    type2Imgs.push(element.childImageSharp)
  })
  const type3Imgs = []
  content['items'][2]['images'].forEach((element) => {
    type3Imgs.push(element.childImageSharp)
  })
  const type4Imgs = []
  content['items'][3]['images'].forEach((element) => {
    type4Imgs.push(element.childImageSharp)
  })
  const type5Imgs = []
  content['items'][4]['images'].forEach((element) => {
    type5Imgs.push(element.childImageSharp)
  })
  const type6Imgs = []
  content['items'][5]['images'].forEach((element) => {
    type6Imgs.push(element.childImageSharp)
  })
  const type7Imgs = []
  content['items'][6]['images'].forEach((element) => {
    type7Imgs.push(element.childImageSharp)
  })
  const type8Imgs = []
  content['items'][7]['images'].forEach((element) => {
    type8Imgs.push(element.childImageSharp)
  })
  return (
    <Layout>
      <section>
        <h1 className={styles.codeHeadline}>{content.headline}</h1>
        <h2 className={styles.codeSubhead}>{content.subline}</h2>
        <p
          dangerouslySetInnerHTML={{ __html: content.byline }}
        />
        <section>
          <h2>History &amp; Context</h2>
            <div>
              <p>
                For much of the last century, the American landscape has largely
                been transformed from a mixture of urban centers and open
                landscapes to the in-between, the neither-nor. Use-based zoning,
                also known as Euclidean zoning, became the norm after a 1926
                Supreme Court decision upheld the town of Euclid Ohio&apos;s
                right to segregate various functions across the community and to
                place limits on individuals&apos; property rights.
                <sup>
                  <a id='fnref:1' href='#fn:1'>
                    1
                  </a>
                </sup>{' '}
                While many of us who work within the traditional building
                community would champion the rights of the public realm over the
                private, in this case the precedent was problematic, creating a
                widely accepted practice of government and city planners (not to
                be confused with urban designers) dictating the single thing
                that a particular lot could be used for as opposed to allowing
                the variety of uses that naturally grow in a vibrant
                neighborhood to support its various needs; and paving the way
                for car culture to drive the mono-functional sprawling manner in
                which our cities would develop for decades to come.
              </p>
            </div>
            <div>
              <p>
                But 30 years ago, Andrés Duany and Elizabeth Plater-Zyberk,
                along with other founders of the Congress for the New Urbanism,
                sought to change that landscape in a new way—by thinking about
                the legal structures that impact the way the built environment
                looks and by creating a new code that was both simple in its own
                form and capable of allowing a rich and complex environment. The
                first application of this code was the Seaside Code, a single
                poster that changed the discussion of traditional urban design
                on its head.
              </p>
            </div>
            <div>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.poster.childImageSharp]}
              />
            </div>
            <div>
              <p>
                The first form-based code since Haussmann&apos;s 19th century
                Paris and New York City&apos;s efforts of 1915-1916, the Seaside
                approach recognized that in a sustainable community, one person
                couldn&apos;t—or shouldn&apos;t— design every building and every
                detail. The buildings would have to grow and evolve over time
                with input from a variety of clients and designers. As Andrés
                Duany notes, &quot;urbanism achieves its resilience and
                diversity not through scale but through the &quot;saddling&quot;
                of time.&quot;
                <sup>
                  <a id='fnref:2' href='#fn:2'>
                    2
                  </a>
                </sup>{' '}
                A stable underpinning of parameters as to scale, placement and
                general form of the various buildings would, though, be critical
                to producing a unified environment through this evolutionary
                process.
              </p>
              <p>
                The Seaside code applies to the various private buildings
                (residential and commercial) in town, broken down into eight
                sections classified by lot type, and addresses the location and
                scale of yards and porches, outbuildings and parking and
                building height based on number of stories. (An architectural
                code determining syntax of individual building elements,
                allowable materials, etc. does exist as well, but is noted by
                Andrés Duany as having &quot;been less important to the
                development of Seaside&quot;.)
                <sup>
                  <a id='fnref:3' href='#fn:3'>
                    3
                  </a>
                </sup>{' '}
                This sort of urban code makes it possible to restrict some uses
                (such as the placement of a tattoo parlor next to the elementary
                school), but recognizes that the needs and an economy of a place
                may change over time and so a grand house now may someday host a
                bed and breakfast or office space. It also recognizes that a
                community is made of a variety of building types and uses,
                economic levels and residential scales. Instead of setting out
                to organize residents by class and income, the Seaside code
                seeks a mixture organized instead based on building form.
              </p>
            </div>
            <div>
              <p>
                The urban (or initially titled &quot;zoning&quot;) code of
                Seaside has changed relatively little over its 30 year history.
                It is perhaps most striking because of its simplicity and
                clarity. Anyone slogging through the tomes of most
                municipalities&apos; codes would feel an immense sense of relief
                from the brain-sucking legalese when presented with this simple
                poster, a small bit of annotation alongside a grid of crisp
                small diagrams which lays out the sophisticated structure of the
                entire place. The Code is closely linked to the masterplan
                (explored in greater depth in{' '}
                <SmartLink to='the-plan'>plan</SmartLink>), a critical element
                in any urban design, and the carefully designed street network.
              </p>
            </div>
            <div>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.urban.childImageSharp]}
              />
            </div>
        </section>
        <section>
          <h2>The Types</h2>
            <div>
              <h2>Type I</h2>
              <div className='image-gallery'>
              <Gallery images={type1Imgs} />
              </div>
              <p>
                Type I lots define the central square along Route 30A. It is
                intended primarily for retail use on the ground floor with
                residential above, potentially also generating hotels and
                rooming houses along the shore. These are the tallest buildings
                at Seaside, with a maximum of five stories permitted. They are
                party-wall buildings with no setback in the front, where a large
                arcade is required. Today, shops such as Modica Market and
                Central Square Records thrive fronting the green.
              </p>
            </div>
            <div>
              <h2>Type II</h2>
              <Gallery images={type2Imgs} />
              <p>
                These lots define a small pedestrian square at the front of the
                proposed town hall. Type II zoning is intended primarily for
                office uses although apartments and retail establishments may
                occur. The Code generates four-story buildings with courtyard
                and smaller buildings at the rear. The provision affecting
                arcades and silhouettes is highly specific and only minimal
                variety is possible. It is intended that this square will have a
                decidedly more sedate and dignified appearance than the central
                square. The prototype is found in the Vieux Carré of New
                Orleans.
              </p>
            </div>
            <div>
              <h2>Type III</h2>
              <Gallery images={type3Imgs} />
              <p>
                This type generates two uses ultimately determined by lot size
                and location. Large lots face the service street which is at the
                rear of the central square buildings. These spaces were intended
                for warehouses, possibly for automobile repair, storage and
                workshops. A firehouse and a service station were also planned
                for this zone but never built. Smaller lots occur along the
                north-south pedestrian route which connects the church with the
                central square. These should generate small shops, and it is
                hoped that a Sunday Market will be housed in these premises.
                Type III generates party-wall buildings with few restrictions
                other than a limit on height.
              </p>
            </div>
            <div>
              <h2>Type IV</h2>
              <Gallery images={type4Imgs} />
              <p>
                These are large lots which line Seaside Avenue connecting the
                central square to the tennis club. Type IV zoning generates
                large freestanding buildings, with substantial out-buildings at
                the rear. This type may become private houses, small apartment
                buildings or bed and breakfast inns. The setbacks on all sides,
                together with a continuous porch mandated for the street front,
                should result in buildings of some grandeur.
              </p>
            </div>
            <div>
              <h2>Type V</h2>
              <Gallery images={type5Imgs} />
              <p>
                This type is a special category for large lots which can contain
                several buildings. The Seaside Code, like other codes which can
                depend on the street front as the baseline for prescription, is
                too rigid in its control of site plans several buildings deep.
                Consequently, there is a minimum of prescriptions and it is
                required that the lots be planned as coherent groupings, with
                the provision that the designs be approved by the municipal
                authority.
              </p>
            </div>
            <div>
              <h2>Type VI</h2>
              <Gallery images={type6Imgs} />
              <p>
                These lots are the sub-urban section of Seaside. They occur on
                north-south streets where there is a view of the sea at the end
                of the street corridor. Lots become slightly smaller toward the
                center of town for a gradual increase of density. Type VI zoning
                generates free-standing houses and encourages small out-
                buildings at the rear, to become guest houses and rental units.
                The requirements for substantial front yards secure the sea view
                for the island units. Picket fences help to maintain the spatial
                section of the street which would otherwise be excessive. The
                prototype is found everywhere in the suburban and rural south.
              </p>
            </div>
            <div>
              <h2>Type VII</h2>
              <Gallery images={type7Imgs} />
              <p>
                This type occurs along the east-west streets where no view of
                the sea is possible. The lots are, therefore, smaller and less
                expensive. Since a view corridor is unnecessary, the front
                setback are minimal. A zero setback is permitted along one of
                the side yards so that houses tend to generate private yards to
                one side. The Charleston single house is the prototype.
              </p>
            </div>
            <div>
              <h2>Type VIII</h2>
              <Gallery images={type8Imgs} />
              <p>
                This type is dispersed throughout the residential areas of the
                town. It occurs at locations which require some degree of
                acknowledgement as gateways of special places. The Code
                provisions are more liberal than those of Type VI and VII,
                permitting slightly greater height and freedom of placement on
                the lot. This provides meaningful variety within the relatively
                homogeneous residential districts.
              </p>
            </div>
        </section>

        <section>
          <h2>Tests, Questions &amp; Critiques</h2>
            <div>
              <p>
                In 1984, with only a few houses actually in place on the Florida
                panhandle site of Seaside, the Seaside Urban Code was used as
                means of teaching (mostly) suburban students about urbanism at
                the Catholic University of America in Washington, D.C. Two
                studios of beginning design students, in five weeks, produced an
                entire model of what the town might one day become. What better
                way to test the limits of the Code than with a group of
                naturally inquisitive and rebellious young college students
                eager to understand and try their own hands? It was an exercise
                in the &quot;fundamental relationship between the individual
                design and the formal order&quot;. &quot;Early in their
                education [these students] learned to appreciate that such
                realities are common to architectural practice; that constraints
                of codes are based on the values of society and are intended to
                produce environments of quality; and that there is a
                relationship between the individual designer&apos;s search for
                order and the similar search made by others who have gone
                before.&quot;{' '}
                <sup>
                  <a id='fnref:4' href='#fn:4'>
                    4
                  </a>
                </sup>{' '}
              </p>
              <p>
                Students were engaged with an ongoing dialogue that long
                preceded the founding of Seaside itself. Here they could
                converse with the worldwide placemaking and building tradition
                of architects and craftsmen over the centuries as well as the
                local vernacular traditions of small Southern towns to which
                Seaside is so intimately connected. Students understood that
                they were not simply making their own mark on a place, but part
                of a larger whole.
              </p>
              <p>
                Rather than being a hindrance to creativity, Seaside&apos;s Code
                has offered the opportunity for—and perhaps even encouraged—the
                greater complexity over time which naturally arises from a place
                designed by and inhabited by many hands. Often lambasted as
                kitsch by its critics or as backward-looking, Seaside in fact
                has a great variety in its architecture, but it is the unifying
                quality of the urban code to which these modernist, vernacular
                and classical constructions all adhere which makes the place. No
                matter the articulation of a façade or the level of a
                detail&apos;s abstraction, buildings share a consistent approach
                to street frontages, scale and hierarchy. Seaside&apos;s success
                lies not in its design limitations, but in its variety and in
                its address and care for the private realm while giving primacy
                to shaping the public realm.
              </p>
            </div>
        </section>
          <div>
            <hr className={styles.footnoteHr} />
          </div>
          <div>
            Images courtesy of DPZ, unless otherwise noted.
            <ol className={styles.codeNote}>
              <li>
                Form Based Codes : A Guide for Planners, Urban Designers,
                Municipalities, and Developers , pg. 7, Daniel G. Parolek, Karen
                Parolek, Paul C. Crawford: Wiley, 2008.
                <a id='fn:1' href='#fnref:1'>
                  ↩
                </a>
              </li>
              <li>
                Andrés Duany submitted files to the Seaside Research Portal
                Friday, August 5, 2011
                <a id='fn:2' href='#fnref:2'>
                  ↩
                </a>
              </li>
              <li>
                Ibid.
                <a id='fn:3' href='#fnref:3'>
                  ↩
                </a>
              </li>
              <li>
                &quot;The Seaside Studio&quot;, Views of Seaside, pg. 62, Peter
                J. Hetzel and Dhiru A. Thadani: Rizzoli Press, 2008.
                <a id='fn:4' href='#fnref:4'>
                  ↩
                </a>
              </li>
            </ol>
          </div>
      </section>
    </Layout>
  )
}

export default TheCode
